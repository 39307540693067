<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Informes de Marketing</h4>
            <div class="small text-muted">Administración de todos los informes de marketing</div>
          </b-col>
          <b-col>            
            <b-icon icon="question-circle-fill" class="h3 pull-right mt-2 module-help-information" v-b-popover.hover="help"></b-icon>            
          </b-col>          
        </b-row>
      </b-card>

      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="4">                                 
              <Widget :configWidget="configWidget" reference="cant_reports_without_reading" :hasAccess="configWidget.elements.cantReportsWithoutReading" />
            </b-col>
            <b-col md="8"></b-col>
          </b-row>
        </b-col>  
        <b-col md="3">
        
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openReport()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Administración de Informes</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de todos los informes de marketing
              </p>
            </b-list-group-item>
          </b-list-group>                     

          <hr v-if="arr.PHPCustom.length">
          <b-list-group class="mt-2" v-if="arr.PHPCustom.length">
            <b-list-group-item v-for="item in arr.PHPCustom" :key="item.id" href="#" class="flex-column align-items-start" @click="openPHPCustom(item)">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.name}}</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                {{item.observations}}
              </p>
            </b-list-group-item>
          </b-list-group>                
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import ServicesPHP from '@/components/modules/users/php/staff/services'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.INFORME_MARKETING,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.INFORME_MARKETING,
          profile_id: Profiles.PERSONAL,
          elements: {             
            cantReportsWithoutReading: true,            
          }
        },
        primaryColor: '',
        arr: {
          PHPCustom: []
        },        
        help: {
          title:'¿Cómo funciona este módulo?',
          content: () => { 
            return `Un usuario con perfil <b>STAFF</b>, puede visualizar todos los reportes generados
                    y cargarle reportes a cualquier cliente.`
          },            
          html: true
        }        
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.cantReportsWithoutReading = Helper.hasAccessWidget(this.configWidget, 'cant_reports_without_reading')      
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
      this.getPHPCustom()
    },   
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      },       
      openReport() {
        this.$router.push({ name: 'ReportsMarketingStaffCrudReport' })
      }, 

      getPHPCustom() {
        ServicesPHP.obtenerPHPByModule(Modules.INFORME_MARKETING)
        .then((response) => {
          this.arr.PHPCustom = response.data       
        })
      },
      openPHPCustom(item) {
        this.$router.push({ name: 'ViewStaffViewPHPCustom', params: {id: item.id} })
      }      
    }    
   
  }
</script>